import { mapAction } from 'CommonUtils/store/state';

export function useLayoutScroll() {
  const dispatchUserScroll = mapAction('layout', 'dispatchUserScroll');
  const dispatchVMScroll = mapAction('layout', 'dispatchVMScroll');

  const scrollVMTo = (x) => {
    dispatchVMScroll();
    window.scrollTo({ top: x, behavior: 'smooth' });
  };

  const scrollToBodyView = () => {
    setTimeout(() => {
      document.body.scrollIntoView({behavior: 'smooth'});
    }, 0);
  };

  onMounted(() => {
    if (typeof document === 'undefined') return;

    // Reset flag on back/forward
    dispatchVMScroll();

    // Keyboard scroll
    document.onkeydown = (e) => {
      const key = e.which || e.keyCode || 0;
      const scrollKeys = [33, 34, 32, 38, 40]; // page up - page dn - spacebar - up - down
      const scrollCombinedKeys = [36, 35]; // ctrl + home/end

      if (scrollKeys.includes(key) || (scrollCombinedKeys.includes(e.ctrlKey) && scrollCombinedKeys.includes(key))) {
        dispatchUserScroll();
      }
    };

    // Browser scroll
    document.onscroll = dispatchUserScroll;

    // detect user scroll through mouse
    // Mozilla/Webkit
    document.addEventListener('DOMMouseScroll', dispatchUserScroll, false);
    document.addEventListener('touchmove', dispatchUserScroll, false);

    // for IE/OPERA etc
    document.onmousewheel = dispatchUserScroll;

    // for all
    document.onwheel = dispatchUserScroll;

    // To reset flag when named anchors are clicked
    document.querySelectorAll("a[href*='#']:not([href='#'])").forEach((el) => (el.onclick = dispatchVMScroll));
  });

  return {
    scrollVMTo,
    scrollToBodyView,
  };
}
