<template>
  <div class="scroll-top__container">
    <a
      class="scroll-top__link"
      @click="scrollToBodyView"></a>
  </div>
</template>
 
<script>
  import { useLayoutScroll } from 'CommonComposables/useLayoutScroll';
 
  export default {
    name: 'GoToTop',
    setup() {
      const { scrollToBodyView } = useLayoutScroll();
 
      return {
        scrollToBodyView,
      };
    },
  };
</script>